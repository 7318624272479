@import 'var';
@import 'reset';
@import 'font';

/** Main **/
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: $fontSize;
  color: var(--blackColor);
  overflow-x: hidden;
}

.bw_container {
  max-width: 1240px;
  padding-left: $numberPading;
  padding-right: $numberPading;
  margin: 0 auto;
  width: 100%;
}

b,
strong {
  font-weight: 700;
}

p {
  font-size: $fontSize;
  line-height: 1.7;
}

a {
  @include BlackwindTransition(300ms);
  font-size: $fontSize;
  text-decoration: none;
}

/** Row.Boostrap **/
.bw_row {
  margin-left: -$numberPading;
  margin-right: -$numberPading;
  @include flex;
}

.bw_h-100 {
  height: 100%;
}

/** Col.Boostrap **/
@for $col from 1 through 12 {
  $colWidth: percentage($col/12);

  .bw_col_#{$col} {
    max-width: $colWidth;
    flex: 0 0 $colWidth;
    // padding-left: $numberPading;
    // padding-right: $numberPading;
    padding: $numberPading;
  }
}

@for $coln from 1 through 10 {
  $colWidthCustom: $coln * 10%;

  .bw_c#{$coln} {
    max-width: $colWidthCustom;
    flex: 0 0 $colWidthCustom;
    padding-left: $numberPading;
    padding-right: $numberPading;
  }
}

@for $max from 1 through 100 {
  $maxWidth: $max * 100px;

  .bw_mw_#{$max} {
    max-width: $maxWidth;
  }
}

@for $width from 1 through 100 {
  $widthMem: $width * 100px;

  .bw_w#{$width} {
    width: $widthMem !important;
  }
}

/** Margin and Padding **/
@for $padding from 1 through 10 {
  $paddingWidth: $padding * 10px;

  .bw_mt_#{$padding} {
    margin-top: $paddingWidth;
  }

  .bw_mb_#{$padding} {
    margin-bottom: $paddingWidth;
  }

  .bw_pt_#{$padding} {
    padding-top: $paddingWidth;
  }

  .bw_pb_#{$padding} {
    padding-bottom: $paddingWidth;
  }
}

/** Flex display **/
.bw_flex {
  @include flex;
}

.bw_align_items_left {
  align-items: flex-start;
}

.bw_align_items_center {
  align-items: center;
}

.bw_align_items_right {
  align-items: flex-end;
}

.bw_align_items_stretch {
  align-items: stretch;
}

.bw_align_items_unset {
  align-items: unset;
}

.bw_justify_content_left {
  justify-content: flex-start;
}

.bw_justify_content_center {
  justify-content: center;
}

.bw_justify_content_right {
  justify-content: flex-end;
}

.bw_justify_content_between {
  justify-content: space-between;
}

.bw_justify_content_around {
  justify-content: space-around;
}

/** Text Align **/
@each $namePos in $position {
  .bw_text_#{$namePos} {
    text-align: $namePos;
  }
}

/** Position **/
@each $namePosV in $pos {
  .bw_position_#{$namePosV} {
    position: $namePosV;
  }
}

/** Display **/
@each $displayName in $display {
  .bw_display_#{$displayName} {
    display: $displayName;
  }
}

/** Button **/
.bw_btn {
  @include BlackwindButton(var(--whiteColorHover), var(--blackColor), var(--whiteColorHover));
  font-size: 14px;

  &:hover {
    background: var(--whiteColorHover);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_primary {
  @include BlackwindButton(var(--blueColor), var(--whiteColor), var(--blueColor));

  &:hover {
    background: var(--blueColorHover);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_danger {
  @include BlackwindButton(var(--redColor), var(--whiteColor), var(--redColor));

  &:hover {
    background: var(--redColorHover);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_warning {
  @include BlackwindButton(var(--ogranceColor), var(--whiteColor), var(--ogranceColor));

  &:hover {
    background: var(--ogranceColorHover);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_success {
  @include BlackwindButton(var(--greenColor), var(--whiteColor), var(--greenColor));

  &:hover {
    background: var(--greenColorHover);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_outline {
  @include BlackwindButton(var(--whiteColor), var(--blackColor), var(--blackColor));

  &:hover {
    background: var(--blackColor);
    color: var(--whiteColor);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_outline_primary {
  color: var(--blueColor);
  border: 1px solid var(--blueColor);

  &:hover {
    background: var(--blueColor);
  }
}

.bw_btn_outline_danger {
  color: var(--redColor);
  border: 1px solid var(--redColor);

  &:hover {
    background: var(--redColor);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_outline_warning {
  color: var(--ogranceColor);
  border: 1px solid var(--ogranceColor);

  &:hover {
    background: var(--ogranceColor);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_outline_success {
  color: var(--greenColor);
  border: 1px solid var(--greenColor);

  &:hover {
    background: var(--greenColor);
  }

  span {
    margin-right: 5px;
    display: flex;
  }
}

.bw_btn_icon {

  span,
  i {
    font-size: 17px;
    display: flex;
    align-items: center;
  }
}

/** Input **/
input[type='color'] {
  height: 33px;
  padding: 0 !important;
  cursor: pointer;
}

.bw_inp {
  @include BlackwindInput(var(--whiteColor), var(--blackColor), var(--borderColor));
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: $fontSize;
  width: 100%;

  &::placeholder {
    color: var(--blackColor);
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid var(--blackColor);
  }

  &:disabled {
    opacity: 0.9;
    background: var(--grayColor);
  }
}

.bw_inp_primary {
  @extend .bw_inp;
  color: var(--blueColorHover);
  border: 1px solid var(--blueColor);

  &::placeholder {
    color: var(--blueColor);
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid var(--blueColorHover);
  }
}

.bw_inp_danger {
  @extend .bw_inp;
  color: var(--redColorHover);
  border: 1px solid var(--redColor);

  &::placeholder {
    color: var(--redColor);
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid var(--redColorHover);
  }
}

.bw_inp_warning {
  @extend .bw_inp;
  color: var(--ogranceColorHover);
  border: 1px solid var(--ogranceColor);

  &::placeholder {
    color: var(--ogranceColor);
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid var(--ogranceColorHover);
  }
}

.bw_inp_success {
  @extend .bw_inp;
  color: var(--greenColorHover);
  border: 1px solid var(--greenColor);

  &::placeholder {
    color: var(--greenColor);
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid var(--greenColorHover);
  }
}

.bw_inp_icon {
  position: relative;
  width: 100%;
  display: block;

  input {
    width: 100%;
  }

  span,
  i {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 17px;
  }

  &.bw_success {
    color: var(--greenColor);
  }

  &.bw_danger {
    color: var(--redColor);
  }
}

@mixin fmr {
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: var(--blackColor);
  font-size: 14px !important;
}

.bw_select {
  display: block;
  width: 100% !important;
  border: none;

  .bw_select_selection--single {
    @include fmr;

    .bw_select_selection__rendered {
      border: none;
      width: 100%;
      font-size: 14px;
      padding: 0 10px;
    }
  }

  .bw_select_selection--multiple {
    border: none !important;
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .bw_select_selection__rendered {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .bw_select_selection__choice {
      background: var(--mainColor);
      color: var(--whiteColor);
      padding: 5px;
      margin: 0 2px;
      padding-left: 30px;
      font-size: 14px;
      border: none;

      .bw_select_selection__choice__remove {
        color: var(--whiteColor);
        border-right: 1px solid var(--whiteColor);
        height: 100%;
        padding: 0 8px;
      }
    }

    .bw_select_search__field {
      height: 30px !important;
      line-height: 30px;
    }

    &:hover,
    &:focus,
    &:active {
      border: none;
    }
  }
}

/** List **/
ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

/** Table **/
.bw_table_responsive {
  display: block;
  max-width: 100%;
  width: auto;
  overflow-x: auto;
  position: relative;
}

.bw_table {
  font-size: 15px;
  border-spacing: 0;
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
  text-indent: initial;
  background: var(--whiteColor);

  tr:nth-child(2n) {
    background: var(--grayColor);
  }

  tr {
    position: relative;
    white-space: nowrap;

    &:hover {
      background: var(--grayColorHover);
    }

    td {
      @include BlackwindTransition(300ms);
      border: none;
      padding: 6px 10px;
      border-bottom: 1px solid #ddd;
      text-align: center;
      box-sizing: content-box;
      table-layout: fixed;
      vertical-align: middle;
      border-left: 1px solid #ddd;

      &:first-child {
        border-left: none !important;
      }

      .bw_avata_table {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 7px;
          object-fit: cover;
        }
      }

      .bw_select_selection--single {
        line-height: normal;
      }

      .bw_select_selection--multiple {
        border: 1px solid #aaa !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .bw_select_selection__rendered {
          display: inline-flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .bw_select_selection__choice {
          background: var(--mainColor);
          color: var(--whiteColor);
          padding: 5px;
          padding-left: 30px;
          font-size: 12px;
          border: none;
          line-height: 12px;
          margin-top: 1px;
          margin-bottom: 1px;

          .bw_select_selection__choice__remove {
            color: var(--whiteColor);
            border-right: 1px solid var(--whiteColor);
            height: 100%;
            padding: 0 8px;
          }
        }

        .bw_select_search__field {
          height: 33px !important;
          line-height: 33px;
          margin-top: 0;
        }

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #aaa;
        }
      }

      .bw_checkbox,
      .bw_radio {
        display: inline-flex;
      }
    }

    &.bw_checked {
      background: #eefffc;

      td {
        background: #eefffc;
      }
    }
  }

  th {
    padding: 5px 10px;
    background: var(--whiteColorHover);
    border-bottom: 1px solid var(--borderColor);
  }

  th.bw_th_border {
    border: 1px solid var(--borderColor) !important;
  }

  th.bw_sticky,
  td.bw_sticky {
    position: -webkit-sticky;
    position: sticky;
    padding: 6px 10px;
    background: var(--whiteColor);
  }

  &.bw_table_center {

    th,
    td {
      text-align: center !important;

      .bw_inp {
        text-align: center;
      }
    }
  }
}

.bw_choose_record {
  font-size: $fontSize;
  color: var(--blackColor);

  b {
    font-weight: 800;
    color: var(--mainColor);
  }

  a {
    font-weight: normal;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    color: var(--mainColor);

    &.bw_red {
      b {
        font-weight: 800;
        color: var(--redColor);
      }
    }
  }
}

.bw_nav_table {
  display: flex;
  align-items: center;

  button {
    @extend .bw_btn;
    margin: 0 7px;
    font-size: 18px;
    padding-bottom: 5px;
    padding: 5px 7px;
    opacity: 0.3;

    &.bw_active {
      opacity: 1;
    }

    span {
      display: flex;
      align-items: center;
      margin-right: 0;
    }
  }

  input {
    width: 80px;
    @extend .bw_inp;
    margin: 0 7px;
    margin-left: 0;
  }

  span.bw_all_page {
    color: var(--blackColor);
    opacity: 0.5;
  }
}

.bw_show_table_page {
  align-items: center;

  p {
    font-size: 14px;
    opacity: 0.5;
  }
}

/** Order **/
.bw_pull_left {
  order: 1;
}

.bw_pull_right {
  order: 2;
}

.bw_radio,
.bw_checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  margin-right: 20px;
  overflow: hidden;

  span {
    width: 16px;
    height: 16px;
    background: var(--whiteColor);
    border: 1px solid var(--borderColor);
    border-radius: 50%;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include BlackwindTransition(300ms);
    overflow: hidden;

    &:not(.bw_radio span) {
      border-radius: 3px;
    }
  }

  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;

    &:checked~span {
      border: 1px solid var(--mainColor);
      background: var(--mainColor);
      overflow: hidden;

      &::after {
        font-family: uicons-regular-rounded !important;
        content: '\f211';
        font-size: 11px;
        line-height: 11px;
        color: var(--whiteColor);
      }
    }
  }

  &.bw_disable {
    background: none;
  }
}

.bw_d_none,
.bw_m_none {
  display: none;
}

.bw_label {
  display: inline-block;
  line-height: normal !important;
  padding: 3px 7px;
  font-size: 13px;
  background: var(--blackColor);
  color: var(--whiteColor);
  border-radius: 3px;
  cursor: pointer;

  &.bw_label_primary {
    background: var(--blueColor);
    color: var(--whiteColor) !important;

    &:hover {
      background: var(--blueColorHover);
    }
  }

  &.bw_label_danger {
    background: var(--redColor);
    color: var(--whiteColor) !important;

    &:hover {
      background: var(--redColorHover);
    }
  }

  &.bw_label_warning {
    background: var(--ogranceColor);
    color: var(--whiteColor) !important;

    &:hover {
      background: var(--ogranceColorHover);
    }
  }

  &.bw_label_success {
    background: var(--greenColor);
    color: var(--whiteColor) !important;

    &:hover {
      background: var(--greenColorHover);
    }
  }

  &:hover {
    background: var(--blackColorHover);
  }
}

.bw_label_outline {
  display: inline-block;
  padding: 3px 7px;
  line-height: normal !important;
  font-size: 13px;
  background: var(--whiteColor);
  color: var(--blackColor);
  border: 1px solid var(--blackColor);
  border-radius: 3px;

  &.bw_label_outline_primary {
    color: var(--blueColor);
    border: 1px solid var(--blueColor);

    &:hover {
      color: var(--blueColorHover);
      border: 1px solid var(--blueColorHover);
    }
  }

  &.bw_label_outline_danger {
    color: var(--redColor);
    border: 1px solid var(--redColor);

    &:hover {
      color: var(--redColorHover);
      border: 1px solid var(--redColorHover);
    }
  }

  &.bw_label_outline_warning {
    color: var(--ogranceColor);
    border: 1px solid var(--ogranceColor);

    &:hover {
      color: var(--ogranceColorHover);
      border: 1px solid var(--ogranceColorHover);
    }
  }

  &.bw_label_outline_success {
    color: var(--greenColor);
    border: 1px solid var(--greenColor);

    &:hover {
      color: var(--greenColorHover);
      border: 1px solid var(--greenColorHover);
    }
  }

  &:hover {
    color: var(--blackColorHover);
    border: 1px solid var(--blackColorHover);
  }
}

@each $colorName in $color {
  .bw_#{$colorName} {
    color: var(--#{$colorName}Color);
  }
}

.bw_modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: -1;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  .bw_modal_container {
    width: 100%;
    max-width: 600px;
    background: var(--whiteColor);
    border-radius: $numberPading;
    padding: 20px;
    max-height: calc(100% - 120px);
    box-shadow: $boxShadow;
    overflow-x: auto;

    // margin-left: 180px;
    .bw_title_modal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;

      h3 {
        font-size: 20px;
        font-weight: 500;
      }

      span {
        font-size: 20px;
        color: var(--blackColor);
        opacity: 0.4;
        cursor: pointer;

        &.bw_icon_notice {
          color: var(--redColor);
          position: relative;
          border-radius: 50%;
          background: var(--grayColor);
          opacity: 1;
          top: 3px;
        }
      }
    }

    .bw_main_modal {
      padding: 15px 0;
    }

    .bw_footer_modal {
      padding-top: 15px;
      border-top: 1px solid var(--borderColor);
      @extend .bw_flex;
      @extend .bw_align_items_center;

      // *:not(span) {
      //   margin: 0 5px;
      //   padding: 10px 20px;
      // }

      button {
        margin: 0 5px;
        padding: 10px 20px;
      }
    }

    &.bw_w700 {
      max-width: 700px;
    }

    &.bw_w800 {
      max-width: 980px;
    }

    &.bw_w900 {
      max-width: 900px;
    }

    &.bw_w1200 {
      max-width: 1200px;
    }
  }

  &.bw_modal_open {
    opacity: 1;
    z-index: 999;
  }
}

.bw_frm_box {
  background: var(--whiteColor);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;

  label {
    display: block;
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 2px;

    &.bw_radio,
    &.bw_checkbox {
      font-size: 15px;
      opacity: 1;
      display: inline-flex;
      align-items: center;
    }
  }

  input,
  textarea {
    border: none;
    @include fmr;
    outline: none;
    display: block;
    width: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-size: $fontSize !important;
    color: var(--blackColor);
    padding: 10px;
    padding-bottom: 0;

    &:hover {
      border: none;
    }

    &::placeholder {
      opacity: 0.2;
    }

    &.bw_inp_comment {
      border: 1px solid var(--borderColor);
      border-radius: 7px;
      margin-bottom: 7px;
      padding: 7px 10px !important;
    }
  }

  textarea {
    min-height: 80px;
  }

  .bw_select {
    display: block;
    width: 100% !important;
    border: none;

    .bw_select_selection--single {
      @include fmr;
      padding-bottom: 0;
      border: none;

      .bw_select_selection__rendered {
        border: none;
        padding: 0;
      }
    }

    .bw_select_selection--multiple {
      border: none !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .bw_select_selection__rendered {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .bw_select_selection__choice {
        background: var(--mainColor);
        color: var(--whiteColor);
        padding: 5px;
        padding-left: 30px;
        margin: 2px !important;
        font-size: 14px;
        border: none;

        .bw_select_selection__choice__remove {
          color: var(--whiteColor);
          border-right: 1px solid var(--whiteColor);
          height: 100%;
          padding: 0 8px;
        }
      }

      .bw_select_search__field {
        height: 30px !important;
        line-height: 30px;
      }

      &:hover,
      &:focus,
      &:active {
        border: none;
      }
    }
  }

  &.bw_readonly,
  &.bw_disable {
    background: #e2e2e2;

    input,
    .bw_select_selection--single,
    textarea {
      background: none !important;
    }
  }

  &.bw_readonly {
    background: #f2f2f2;
  }

  .bw_show_pass {
    @extend .bw_btn;
    color: var(--borderColor);
    cursor: pointer;
    border: none;
    background: var(--whiteColor);
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.bw_collapse {
  padding: 20px;
  background: var(--grayColor);

  .bw_collapse_title {
    display: flex;
    align-items: center;
    cursor: pointer;

    h3,
    h4,
    h5 {
      font-size: 15px;
      font-weight: 600;
    }

    span.fi {
      width: 26px;
      height: 26px;
      background: var(--whiteColor);
      border-radius: 50%;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-right: 10px;
      @include BlackwindTransition(300ms);
    }
  }

  .bw_collapse_panel {
    padding: 15px 0;
    padding-bottom: 0;
    display: none;
    @include BlackwindTransition(300ms);
  }

  &.bw_active {
    .bw_collapse_title {
      span.fi {
        transform: rotate(180deg);
        color: var(--mainColor);
      }
    }

    .bw_collapse_panel {
      display: block;
    }
  }
}

.bw_choose_image {
  position: relative;
  width: 120px;
  height: 120px;
  background: var(--whiteColor);
  border-radius: 50%;
  border: 1px solid var(--borderColor);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
  }

  span {
    font-size: 40px;
    color: var(--borderColor);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.bw_choose_image_table {
  @extend .bw_choose_image;
  width: 43px;
  height: 43px;
  background: none;
  color: var(--blackColor);

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
  }

  span {
    font-size: 20px;
    color: var(--borderColor);
    height: 20px;
    display: inline-flex;
    align-items: center;
  }
}

.bw_choose_file {
  @extend .bw_choose_image;
  width: 100%;
  height: auto;
  border-radius: 10px;
  color: #999;
  padding: 20px 0;
}

.bw_tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);

  li {
    opacity: 0.6;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;

    a {
      font-size: $fontSize;
      padding: 10px 0;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      span {
        margin-left: 7px;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 2px;
        width: 0px;
        background-color: transparent;
        content: '';
        transition: all 300ms;
      }
    }

    span.bw_remove_tabs {
      font-size: 11px;
      display: inline-block;
      line-height: 14px;
      padding-left: 10px;
      cursor: pointer;
      position: relative;
      top: 2px;
    }

    &.bw_active,
    &:hover {
      opacity: 1;

      a {
        color: var(--mainColor);

        &::after {
          width: 100%;
          background-color: var(--mainColor);
        }
      }
    }

    &:first-child {
      padding-left: 0;
    }
  }
}

.bw_tab_items {
  display: none;
  padding: 20px 0;

  &.bw_active {
    display: block;
  }
}

.bw_relative {
  position: relative;
}

.bw_absolute {
  position: absolute;
}

.bw_badge {
  @extend .bw_label;
  background: var(--borderColor);
  color: var(--blackColor);

  &.bw_badge_success {
    background: #def1ee;
    color: var(--greenColor);
  }

  &.bw_badge_primary {
    background: #dee2f1;
    color: var(--blueColor);
  }

  &.bw_badge_warning {
    background: #f3ece7;
    color: var(--ogranceColor);
  }

  &.bw_badge_danger {
    background: #f3e7e7;
    color: var(--redColor);
  }

  &:hover {
    background: var(--borderColor);
    color: var(--blackColor);
  }
}

.bw_no_pt {
  padding-top: 0 !important;
}

.bw_confirm_level {
  li {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    position: relative;
    opacity: 0.7;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 17px;
    }

    span {
      width: 17px;
      height: 17px;
      display: inline-flex;
      border-radius: 10px;
      font-size: 9px;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--borderColor);
      background: var(--whiteColor);
      color: var(--blackColor);
      position: absolute;
      left: 23px;
      bottom: 0;
    }

    i {
      font-size: 12px;
      font-style: italic;
    }

    &.bw_agree {
      opacity: 1;

      span {
        background: var(--mainColor);
        color: var(--whiteColor);
        border: 1px solid var(--mainColor);
      }
    }

    &.bw_non_agree {
      opacity: 1;

      span {
        background: var(--redColor);
        color: var(--whiteColor);
        border: 1px solid var(--redColor);
      }
    }
  }
}

.bw_choose_image_banner {
  @extend .bw_choose_image;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  span {
    font-size: 27px;
  }
}

.bw_import_file {
  background: var(--grayColor);
  border-radius: 10px;
  min-height: 80px;
  border: 1px solid var(--borderColor);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: var(--borderColor);

  input {
    position: absolute;
    opacity: 0;
    width: 1px;
  }

  span {
    font-size: 40px;
    color: var(--borderColor);
    padding-right: 12px;
  }
}

.bw_input_right input {
  text-align: right !important;
}

.bw_input_center input {
  text-align: center !important;
}

.bw_btn_watch {
  transition: all 300ms;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  color: var(--whiteColor);
  border: 1px solid #ec808d;
  background: #ec808d;
}

.bw_btn_watch:hover {
  background: #ec7280;
}

.bw_btn_watch span {
  margin-right: 5px;
  display: flex;
}

.custom-booking #trigger-delete{
  display: none !important;
}

.custom-form-number-vat input {
  text-align: right !important;
}

.custom-form-number-quantity-expected input {
  text-align: right !important;
}

.custom-form-number-quantity-reality input {
  text-align: right !important;
}

.hover-custom_tooltip:hover .custom_tooltip{
  display: block;
}

.custom_tooltip{
  display: none;
  position: absolute;
  top: 43px;
  z-index: 2;
  background: #000;
  padding: 5px;
  color: #fff;
  border-radius: 8px;
}

.disabled_trash_icon span.ant-upload-list-item-actions {
  display: none;
}