:root {
    /** Site Color **/
    --mainColor: #576cbc;
    --secondColor: #0b2447;
    --borderColor: #dddddd;
    --menuColor: #eeeeee;
    --borderMenuColor: #19376d;
    /** Default Color **/
    --blackColor: #333;
    --blackColorHover: #111111;
    --blueColor: #2f80ed;
    --blueColorHover: #175db8;
    --greenColor: #2f80ed;
    --greenColorHover: #175db8;
    --grayColor: #f8f8f8;
    --grayColorHover: #f0f0f0;
    --ogranceColor: #f2994a;
    --ogranceColorHover: #db7d2b;
    --redColor: #ec2d41;
    --redColorHover: #ca192b;
    --whiteColor: #ffffff;
    --whiteColorHover: #f2f2f2;
    --yellowColor: #ffff00;
    --yellowColorHover: #d0d005;
    --transparent: transparent;
    --pinkColor: #dd37ec;
    --violetColor: #6726ba;
}

.bw_contain {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.bw_contain .bw_sidebar {
    width: 280px;
    background: var(--secondColor);
    height: 100vh;
    position: fixed;
    z-index: 3;
    transition: ease-in-out 300ms;
}

.bw_contain .bw_sidebar .bw_logo {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-bottom: 1px solid var(--borderMenuColor);
    position: relative;
}

.bw_contain .bw_sidebar .bw_logo .bw_main_logo {
    display: block;
}

.bw_contain .bw_sidebar .bw_logo .bw_footer_logo {
    display: none;
}

.bw_contain .bw_sidebar .bw_logo img {
    max-height: 30px;
}

.bw_contain .bw_sidebar .bw_logo #bw_close_nav {
    width: 23px;
    height: 23px;
    border-radius: 14px;
    background: var(--mainColor);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--whiteColor);
    font-size: 14px;
    line-height: 15px;
    position: absolute;
    transition: all 300ms;
    right: -10px;
}

.bw_contain .bw_sidebar .bw_logo #bw_close_nav.bw_active {
    transform: rotate(180deg);
}

.bw_contain .bw_sidebar .bw_user_admin {
    padding: 15px 25px;
    border-bottom: 1px solid var(--borderMenuColor);
    color: var(--whiteColor);
}

.bw_contain .bw_sidebar .bw_user_admin img {
    max-width: 40px;
    max-height: 40px;
    -o-object-fit: cover;
    object-fit: contain;
    margin-right: 10px;
}

.bw_contain .bw_sidebar .bw_main_menu {
    margin-top: 20px;
    max-height: calc(100% - 181px);
    position: relative;
    overflow-y: auto;
    padding: 0 10px;
    display: block;
}

.bw_contain .bw_sidebar .bw_main_menu li {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    transition: all 300ms;
    margin-bottom: 2px;
    position: relative;
}

.bw_contain .bw_sidebar .bw_main_menu li a {
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    color: var(--menuColor);
    padding: 12px 0;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
}

.bw_contain .bw_sidebar .bw_main_menu li a span.fi {
    font-size: 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    opacity: 0.7;
}

.bw_contain .bw_sidebar .bw_main_menu li a span.bw_hideen_nav {
    display: block;
    opacity: 1;
    transition: all 60ms;
}

.bw_contain .bw_sidebar .bw_main_menu li i {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--whiteColor);
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
}

.bw_contain .bw_sidebar .bw_main_menu li ul {
    width: 100%;
    display: none;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li {
    padding-left: 36px;
    padding-right: 0;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li a {
    padding: 9px 0;
    opacity: 0.8;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li a:hover {
    opacity: 1;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li ul {
    display: none;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li ul li {
    padding: 0 10px;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li ul li a {
    width: 100%;
    border-left: 1px solid var(--whiteColor);
    padding-left: 10px;
}

.bw_contain .bw_sidebar .bw_main_menu li ul li.bw_active a {
    color: var(--whiteColor);
    opacity: 1;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active {
    background-color: var(--mainColor);
    border-radius: 5px;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active a {
    opacity: 1;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active a span {
    opacity: 1;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active ul {
    display: block;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active ul ul {
    display: none;
}

.bw_contain .bw_sidebar .bw_main_menu li.bw_active ul li.bw_active ul {
    display: block;
}

.bw_contain .bw_sidebar .bw_main_menu li:hover {
    background-color: var(--mainColor);
    border-radius: 5px;
}

.bw_contain .bw_sidebar .bw_main_menu::-webkit-scrollbar {
    display: none;
}

.bw_contain .bw_main {
    width: calc(100vw - 280px);
    position: relative;
    z-index: 0;
    margin-left: 280px;
    transition: all 300ms;
    min-height: 100vh;
}

.bw_contain .bw_main header {
    height: 60px;
    padding: 0 25px;
    border-bottom: 1px solid var(--borderColor);
    position: sticky;
    top: 0;
    z-index: 20;
    background: #f8f8f8;
}

.bw_contain .bw_main header h1 {
    font-size: 22px;
    color: var(--textColor);
    font-weight: 700;
    max-width: 50%;
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.bw_contain .bw_main header h1 a {
    font-size: 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 15px;
    color: var(--mainColor);
}

.bw_contain .bw_main header .bw_right_header {
    max-width: 50%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bw_contain .bw_main header .bw_right_header .bw_btn_header {
    border-radius: 7px;
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 15px;
    padding: 7px 15px;
    transition: all 300ms;
    color: var(--whiteColor);
    background: #f4f5f6;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    position: relative;
}

.bw_contain .bw_main header .bw_right_header .bw_btn_header span:not(span.fi) {
    width: 8px;
    height: 8px;
    position: absolute;
    background: var(--redColor);
    border-radius: 8px;
    left: 28px;
    top: 5px;
}

.bw_contain .bw_main header .bw_right_header .bw_btn_header span.fi {
    font-size: 20px;
    color: var(--mainColor);
}

.bw_contain .bw_main header .bw_right_header .bw_box_list {
    position: absolute;
    top: -400px;
    right: 0;
    background: var(--whiteColor);
    box-shadow: 0 0 30px rgba(212, 161, 161, 0.25);
    width: 320px;
    border-radius: 5px;
    opacity: 0;
    z-index: -1;
    transition: all 300ms;
    overflow: hidden;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list h3:not(.bw_list_items h3) {
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list h3:not(.bw_list_items h3) i {
    font-size: 13px;
    position: relative;
    right: 15px;
    cursor: pointer;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items {
    padding: 5px;
    border-bottom: 1px solid var(--borderColor);
    cursor: pointer;
    position: relative;
    display: block;
    padding-left: 60px;
    color: var(--blackColor);
    transition: all 300ms;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items span.fi {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f5f6;
    /* top: 10px; */
    top: 3px;
    left: 10px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: var(--mainColor);
    border-radius: 50%;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items span:not(span.fi) {
    font-size: 13px;
    color: var(--secondColor);
    opacity: 0.5;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items:last-child {
    border-bottom: none;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items:hover {
    background: var(--borderColor);
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items h3 {
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 0;
    font-weight: 400;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items.bw_show {
    display: block;
    z-index: 22;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items.bw_non_read span.fi {
    background: var(--mainColor);
    color: var(--whiteColor);
}

.bw_contain .bw_main header .bw_right_header .bw_box_list .bw_list_items.bw_non_read h3 {
    font-weight: 600;
    margin-top: 0;
    padding: 0;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_show {
    top: 100%;
    z-index: 22;
    opacity: 1;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control {
    padding: 10px 15px;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control p {
    font-weight: 600;
    margin-bottom: 7px;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control span {
    position: absolute;
    right: 5px;
    top: 10px;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control .bw_choose_layput {
    max-width: 48%;
    margin-right: 0;
    flex-wrap: wrap;
    justify-content: center;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control .bw_choose_layput img {
    width: 100%;
    border-radius: 7px;
    margin-bottom: 7px;
}

.bw_contain .bw_main header .bw_right_header .bw_box_list.bw_control .bw_choose_layput i {
    font-size: 13px;
    opacity: 0.7;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option {
    margin-left: 15px;
    cursor: pointer;
    position: relative;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option img {
    max-width: 40px;
    max-height: 40px;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 25px;
    margin-right: 7px;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list {
    position: absolute;
    top: -400px;
    right: 0;
    background: var(--whiteColor);
    box-shadow: 0 0 30px rgba(212, 161, 161, 0.25);
    width: 200px;
    border-radius: 5px;
    opacity: 0;
    z-index: -1;
    transition: all 300ms;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list ul {
    max-width: 100%;
    width: 100%;
    padding: 5px;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list ul li {
    width: 100%;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list ul li a {
    display: flex;
    align-items: center;
    padding: 5px;
    color: var(--blackColor);
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list ul li a span {
    margin-right: 10px;
    display: inline-block;
}

.bw_contain .bw_main header .bw_right_header .bw_more_option .bw_account_list.bw_show {
    top: 100%;
    z-index: 2;
    opacity: 1;
}

.bw_contain .bw_main.bw_main_kapan {
    height: 100vh;
}

.bw_contain.bw_close_nav .bw_sidebar {
    max-width: 110px;
}

.bw_contain.bw_close_nav .bw_sidebar .bw_main_logo {
    display: none;
}

.bw_contain.bw_close_nav .bw_sidebar .bw_footer_logo {
    display: block;
}

.bw_contain.bw_close_nav .bw_sidebar span.bw_hideen_nav {
    display: none !important;
    opacity: 0;
}

.bw_contain.bw_close_nav .bw_main {
    width: calc(100vw - 110px);
    margin-left: 110px;
}

.bw_contain.bw_second_display {
    flex-wrap: wrap;
}

.bw_contain.bw_second_display .bw_sidebar {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    position: relative;
}

.bw_contain.bw_second_display .bw_sidebar .bw_logo {
    border-bottom: none;
}

.bw_contain.bw_second_display .bw_sidebar .bw_user_admin {
    padding: 10px 20px;
    border-bottom: none;
}

.bw_contain.bw_second_display .bw_sidebar .bw_main_menu {
    display: block;
    border-top: 1px solid var(--borderMenuColor);
    margin-top: 0;
    height: 43px;
    max-height: none;
}

.bw_contain.bw_second_display .bw_sidebar .bw_main_menu li {
    display: inline-flex;
    width: auto;
    max-width: none;
    white-space: nowrap;
    border-right: 1px solid var(--borderMenuColor);
    border-radius: 0;
}

.bw_contain.bw_second_display .bw_sidebar #bw_close_nav {
    display: none;
}

.bw_contain.bw_second_display .bw_main {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
}

.bw_ft {
    padding: 12px 20px;
    position: absolute;
    border-top: 1px solid var(--borderColor);
    bottom: 0;
    left: 0;
    right: 0;
}

.bw_ft p {
    font-size: 12px;
    color: var(--borderMenuColor);
}

.bw_main_wrapp {
    padding: 20px;
    position: relative;
    /* padding-bottom: 60px; */
    z-index: 1;
}

.bw_main_wrapp.bw_kp_nonshow {
    display: none !important;
}

.bw_count_cus {
    display: inline-flex;
    align-items: center;
    background: var(--grayColor);
    padding: 10px 15px;
    border-radius: 5px;
    margin-right: 10px;
}

.bw_count_cus img {
    margin-right: 10px;
}

.bw_count_cus b {
    padding-left: 10px;
}

.bw_btn_group .bw_btn:not(form button),
.bw_btn_group .bw_btn_outline {
    margin-right: 10px;
}

.bw_btn_group .bw_search {
    position: relative;
    max-width: 320px;
    margin-right: 10px;
}

.bw_btn_group .bw_search input {
    width: 100%;
    padding-right: 50px;
}

.bw_btn_group .bw_search button {
    position: absolute;
    top: -1px;
    right: 0;
}

.bw_table_responsive::-webkit-scrollbar {
    width: 100%;
    height: 10px;
}

.bw_table_responsive::-webkit-scrollbar-track {
    background: var(--grayColor);
}

.bw_table_responsive::-webkit-scrollbar-thumb {
    background: var(--borderColor);
    cursor: pointer;
}

.bw_table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-y: hidden;
    font-size: 14px;
}

.bw_table tr.bw_danger {
    background: #f3e7e7 !important;
}

.bw_table tr.bw_danger td {
    background: #f3e7e7 !important;
    color: var(--redColor);
}

.bw_table tr.bw_success {
    background: #eefffc !important;
}

.bw_table tr.bw_success td {
    background: #eefffc !important;
    color: var(--greenColor);
}

.bw_table tr.bw_child_tr {
    display: none;
    background: var(--grayColor) !important;
}

.bw_table tr.bw_child_tr td:first-child {
    padding-left: 40px;
}

.bw_table tr.bw_child_tr.bw_active {
    display: table-row;
}

.bw_table tr.bw_ss td {
    padding: 5px 10px;
}

.bw_table thead th {
    background: var(--secondColor);
    color: var(--whiteColor);
    font-weight: 700;
    border-bottom: none;
    text-align: left;
}

.bw_table thead th.bw_sticky {
    background: var(--secondColor);
    left: 0;
}

.bw_table thead th.bw_sticky_right {
    background: var(--secondColor);
    left: 80;
}

.bw_table thead th.bw_check_sticky {
    width: 40px;
    text-align: center !important;
}

.bw_table thead th.bw_check_sticky label {
    margin-right: 0;
    height: 20px !important;
    display: inline-block;
}

.bw_table thead th.bw_check_sticky label span {
    margin-right: 0;
    overflow: hidden;
}

.bw_table thead th.bw_name_sticky {
    left: 38px;
}

.bw_table thead th.bw_name_sticky_right {
    right: 70px;
}

.bw_table thead th.bw_action_table {
    right: 0;
}

.bw_table thead th.bw_text_center {
    text-align: center !important;
}

.bw_table thead th.bw_hiden {
    display: none;
}

.bw_table thead th.bw_hiden.bw_show {
    display: table-cell;
}

.bw_table thead tr.bw_thead_tble_show th {
    border-bottom: 1px solid var(--borderMenuColor);
    border-left: 1px solid var(--borderMenuColor);
}

.bw_table td {
    text-align: left !important;
}

.bw_table td a:not(.bw_btn_table) {
    color: var(--mainColor);
}

.bw_table td.bw_check_sticky {
    left: 0;
    width: 47px;
    background: var(--whiteColorHover);
    text-align: center !important;
}

.bw_table td.bw_check_sticky label {
    margin-right: 0;
    height: 20px !important;
    display: inline-block;
}

.bw_table td.bw_check_sticky label span {
    margin-right: 0;
    overflow: hidden;
}

.bw_table td.bw_name_sticky {
    left: 38px;
    background: var(--whiteColorHover);
}

.bw_table td.bw_name_sticky_custom_right {
    right: 195px;
    background: var(--whiteColorHover);
}

.bw_table td.bw_name_sticky_right {
    right: 70px;
    background: var(--whiteColorHover);
}

.bw_table td.bw_action_table {
    right: 0;
    max-width: 180px;
}

.bw_table td span.bw_label {
    font-size: 12px;
    border-radius: 5px;
    margin-left: 7px;
}

.bw_table td.bw_text_center {
    text-align: center !important;
    min-width: 60px;
}

.bw_table td.bw_text_left {
    text-align: left !important;
}

.bw_table td.bw_text_right {
    text-align: right !important;
}

.bw_table td .bw_inp {
    min-width: 160px;
    width: 100%;
}

.bw_table td .bw_inp[list='bw_listCate'] {
    padding-right: 5px;
}

.bw_table td.bw_hiden {
    display: none;
}

.bw_table td.bw_hiden.bw_show {
    display: table-cell;
}

.bw_table.bw_table_role tr {
    background: var(--whiteColor);
}

.bw_table.bw_table_role tr td,
.bw_table.bw_table_role th {
    text-align: center !important;
}

.bw_table.bw_table_role tr td .bw_checkbox,
.bw_table.bw_table_role th .bw_checkbox {
    margin: 0 !important;
}

.bw_table.bw_table_role tr td:first-child,
.bw_table.bw_table_role th:first-child {
    text-align: left !important;
}

.bw_table.bw_table_role tr td:not(:first-child),
.bw_table.bw_table_role th:not(:first-child) {
    max-width: 60px !important;
}

.bw_table.bw_table_role th {
    white-space: break-spaces;
}

.bw_table.bw_table_timekeeping tr {
    background: var(--whiteColor);
}

.bw_table.bw_table_timekeeping p {
    font-size: 13px;
    line-height: 20px;
    color: #444;
}

.bw_table.bw_table_timekeeping p b {
    font-size: 14px;
    color: var(--blackColor);
}

.bw_table.bw_table_timekeeping p span {
    font-weight: 600;
}

.bw_table.bw_table_timekeeping p a {
    font-size: 12px;
    padding: 3px 7px;
    cursor: pointer;
}

.bw_table.bw_calendar {
    text-align: center;
    border: 1px solid var(--borderColor);
    border-bottom: none;
    text-align: center;
    border-radius: 0;
    text-align: center;
}

.bw_table.bw_calendar tr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.bw_table.bw_calendar td {
    text-align: center !important;
    display: inline-block;
    width: calc(14.2857142857% - 31px) !important;
}

.bw_table.bw_calendar tr.bw_day td {
    font-weight: 600;
}

.bw_table.bw_calendar tr.bw_calen_items td {
    max-width: 14.2857142857%;
    height: calc((100vh - 120px) / 6);
    text-align: left !important;
    vertical-align: top;
    background: var(--whiteColor);
    white-space: unset;
}

.bw_table.bw_calendar tr.bw_calen_items td span.bw_title_calend {
    display: flex;
    font-size: 12px;
    width: 100%;
}

.bw_table.bw_calendar tr.bw_calen_items td .bw_list_items {
    width: 100%;
    padding: 0 10px;
    color: var(--whiteColor);
    display: block;
    font-size: 13px;
    line-height: 25px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}

.bw_table.bw_calendar tr.bw_calen_items td .bw_list_items.bw_date_s {
    width: calc(var(--widthitems));
    position: relative;
    z-index: 222;
}

.bw_table.bw_calendar tr.bw_calen_items td.bw_ban {
    background: var(--grayColor);
    cursor: not-allowed;
    opacity: 0.3;
}

.bw_btn_table,
.bw_list_email .bw_active_items a,
.bw_items_job .bw_active_items a {
    font-size: 17px;
    width: 33px;
    height: 33px;
    line-height: 17px;
    display: flex;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    background: var(--grayColorHover);
    border-radius: 5px;
}

.bw_btn_table:hover,
.bw_list_email .bw_active_items a:hover,
.bw_items_job .bw_active_items a:hover {
    transition: scale(1.1, 1.1);
}

.bw_modal#bw_box_filter {
    justify-content: flex-end;
    padding-right: 0;
    transition: all 300ms;
    left: calc(100% + 320px);
    top: 0;
    opacity: 0;
}

.bw_modal#bw_box_filter .bw_modal_container.bw_filter {
    z-index: 30;
    background: var(--grayColor);
    max-width: 320px;
    width: 100%;
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    transition: all 600ms;
    position: relative;
    left: -320px;
    opacity: 0;
}

.bw_modal#bw_box_filter.bw_modal_open {
    left: 0;
    opacity: 1;
}

.bw_modal#bw_box_filter.bw_modal_open .bw_modal_container.bw_filter {
    left: 0;
    opacity: 1;
}

.bw_modal#bw_box_filter .bw_btn_filter {
    margin-top: 30px;
}

.bw_modal#bw_box_filter .bw_btn_filter button {
    margin-left: 10px;
}

.bw_control_form {
    padding: 5px 15px;
    background: var(--grayColor);
    border-radius: 7px;
    position: sticky;
    top: 60px;
    transition: all 300ms;
}

.bw_control_form li a {
    padding: 7px 0;
    color: var(--blackColor);
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.bw_control_form li a span {
    font-size: 11px;
    background: var(--borderColor);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 10px;
}

.bw_control_form li a.bw_active {
    color: var(--mainColor);
}

.bw_control_form li a.bw_active span {
    color: var(--whiteColor);
    background: var(--mainColor);
}

.bw_items_frm {
    background: var(--grayColor);
    border-radius: 7px;
    /* margin-bottom: 15px; */
}

.bw_load_image p {
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    margin-top: 3px;
}

.bw_load_image img {
    max-width: 170px;
}

.bw_lb_sex {
    padding: 7px 0 !important;
    flex-wrap: wrap;
}

.bw_lb_sex label {
    width: 25%;
    margin-right: 0;
    margin-bottom: 0;
}

.bw_lb_sex.bw_sex_group label {
    width: 50%;
    margin-bottom: 0;
}

.bw_btn_save {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 30px rgba(212, 161, 161, 0.25);
    z-index: 1;
    background: var(--whiteColor);
    flex-wrap: wrap;
}

.bw_btn_save .bw_btn,
.bw_btn_save .bw_btn_outline {
    font-size: 16px;
    padding: 10px 20px;
    margin-right: 0;
    margin-left: 10px;
}

.bw_btn_save .bw_total_s {
    width: 100%;
    text-align: right;
    padding-bottom: 15px;
}

.bw_btn_save .bw_total_s p {
    font-size: 24px;
    font-weight: 700;
}

.bw_btn_save .bw_total_s span {
    font-size: 16px;
    font-style: italic !important;
}

.bw_border_bottom {
    border-bottom: 1px solid var(--borderColor);
}

.bw_change_password {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 7px 8px;
}

.bw_change_password span {
    position: relative;
    top: 2px;
}

form.bw_frm_change {
    background: var(--grayColorHover) !important;
    padding: 15px;
    padding-bottom: 5px;
}

.bw_ms {
    margin-left: 40px;
}


/*Login*/

.bw_main_login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondColor);
}

.bw_main_login .bw_form_login {
    display: block;
    background: var(--mainColor);
    padding: 60px 50px;
    text-align: center;
    max-width: 450px;
    width: 100%;
    border-radius: 30px;
    padding-bottom: 20px;
}

.bw_main_login .bw_form_login img {
    max-width: 75px;
}

.bw_main_login .bw_form_login h1 {
    font-size: 25px;
    color: var(--whiteColor);
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    max-width: 100%;
}

.bw_main_login .bw_form_login .bw_inp {
    display: block;
    width: 100%;
    margin: 12px 0;
    background: none;
    padding: 12px 20px;
    font-size: 16px;
    color: var(--whiteColor);
    border: 1px solid #666;
    border-radius: 50px;
    text-align: center;
}

.bw_main_login .bw_form_login .bw_inp::-moz-placeholder {
    color: var(--whiteColor);
}

.bw_main_login .bw_form_login .bw_inp::placeholder {
    color: var(--whiteColor);
}

.bw_main_login .bw_form_login .bw_btn_login {
    background: var(--whiteColor);
    border-color: var(--whiteColor);
    color: var(--secondColor);
    padding: 12px 60px;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 600;
    margin: 10px 0;
}

.bw_main_login .bw_form_login .bw_btn_login:hover {
    background: var(--secondColor);
    border-color: var(--secondColor);
    color: var(--whiteColor);
}

.bw_main_login .bw_form_login p {
    margin: 15px 0;
    color: var(--whiteColorHover);
}

.bw_main_login .bw_form_login .bw_fogot_pass {
    border-top: 1px solid #666;
}

@keyframes growProgressBar {
    0%,
    33% {
        --pgPercentage: 0;
    }
    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

.bw_list_sevice {
    position: relative;
}

.bw_list_sevice .bw_service_items {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.bw_list_sevice .bw_service_items h3 {
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
    font-size: 19px;
    display: block;
    font-weight: 600;
    position: relative;
    z-index: 2;
}

.bw_list_sevice .bw_service_items h3::after {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--borderColor);
    background: var(--whiteColor);
    content: '';
    right: -10px;
    top: 0;
    position: absolute;
}

.bw_list_sevice .bw_service_items .bw_service_items_info {
    max-width: 400px;
    padding: 20px;
    width: 100%;
    background: var(--grayColor);
    border-radius: 20px;
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
}

.bw_list_sevice .bw_service_items .bw_service_items_info .bw_content_cus {
    width: 60%;
}

.bw_list_sevice .bw_service_items .bw_service_items_info .bw_content_cus h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.bw_list_sevice .bw_service_items .bw_service_items_info .bw_content_cus p {
    font-size: 14px;
    line-height: 1.35;
    opacity: 0.6;
}

.bw_list_sevice .bw_service_items .bw_service_items_info .bw_content_cus a {
    font-size: 13px;
    margin-top: 10px;
    padding: 5px 7px;
}

.bw_list_sevice .bw_service_items .bw_service_items_info .bw_progcess {
    width: 40%;
    text-align: center;
    padding: 0 20px;
}

.bw_list_sevice .bw_service_items.bw_service_items__home h3 {
    font-size: 14px;
    width: calc(50% - 50px);
    margin-top: 7px;
}

.bw_list_sevice .bw_service_items.bw_service_items__home .bw_service_items_info {
    width: calc(50% + 20px);
    padding: 0;
    border-radius: 0;
}

.bw_list_sevice .bw_service_items.bw_service_items__home .bw_service_items_info h4 {
    font-size: 15px;
}

.bw_list_sevice .bw_service_items.bw_service_items__home .bw_service_items_info p {
    font-size: 13px;
    line-height: 18px;
    opacity: 0.7;
}

.bw_list_sevice::after {
    width: 1px;
    height: 100%;
    left: 200px;
    top: 0;
    background: var(--borderColor);
    content: '';
    position: absolute;
    z-index: 1;
}

.bw_list_sevice.bw_list_service_home:after {
    left: calc(50% - 50px);
}

div[role='bw_progressbar'] {
    --bg: #def;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    place-items: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0), conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-size: 20px;
    color: var(--fg);
}

div[role='bw_progressbar']::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
}

.bw_add_us {
    margin-top: 20px;
    font-size: 13px !important;
}

.bw_add_us span {
    font-size: 12px;
}

.bw_inf_prodetail,
.bw_mores_detail {
    background: var(--grayColor);
    padding: 10px;
    overflow: hidden;
    padding-left: 110px;
    position: relative;
}

.bw_inf_prodetail img,
.bw_mores_detail img {
    max-width: 90px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.bw_inf_prodetail p,
.bw_mores_detail p {
    font-size: 13px;
    color: #444;
    margin-top: 5px;
}

.bw_inf_prodetail p span,
.bw_mores_detail p span {
    display: inline-block;
    padding: 2px 7px;
    color: var(--whiteColor);
    background: var(--mainColor);
    border-radius: 5px;
}

.bw_mores_detail {
    padding: 10px;
}

.bw_mores_detail p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.bw_mores_detail p span {
    background: none;
    color: inherit;
    font-weight: 500;
}

.bw_mores_detail p span.bw_red {
    color: var(--redColor);
}

.bw_list__contentcus {
    width: 100%;
}

.bw_list__contentcus li {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.bw_list__contentcus li b {
    font-weight: 700;
}

.bw_inf_pro {
    display: flex;
    align-items: center;
}

.bw_inf_pro img {
    width: 35px !important;
    height: 35px !important;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.bw_text_s {
    display: block;
    width: 100%;
    min-height: 80px;
}

.bw_abs_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.5);
}

.bw_confirm_items {
    display: flex;
}

.bw_confirm_items img {
    margin-right: 20px;
    width: 90px;
    height: 90px;
}

.bw_confirm_items ul li {
    margin: 7px 0;
}

.bw_image_view {
    width: 100%;
}

.bw_image_view img {
    width: 100%;
    max-width: 100%;
}

.bw_image_view_banner {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.bw_image_view_banner img {
    width: 100%;
    max-width: 100%;
}

.bw_image_view_banner span {
    width: 26px;
    height: 26px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.7);
    color: var(--whiteColor);
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: auto;
}

.bw_view_prints {
    text-align: center;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    background: var(--whiteColor);
    padding: 5px;
    position: sticky;
    top: 182px;
}

.bw_view_prints p {
    font-size: 14px;
    line-height: 1.4;
}

.bw_view_prints img {
    width: 80%;
    margin: 5px 0;
}

.bw_view_prints span {
    font-size: 17px;
    font-weight: 700;
}

.bw_note {
    margin-bottom: 20px;
}

.bw_note h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}

.bw_dashboard {
    background: var(--grayColor);
}

.bw_card_items {
    padding: 10px 15px;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.05);
    background: var(--whiteColor);
    border-radius: 5px;
    position: relative;
}

.bw_card_items span.bw_icon_card {
    font-size: 24px;
    line-height: 20px;
    color: var(--mainColor);
    background: #e9f6f4;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.bw_card_items span.bw_icon_card.bw_red {
    color: var(--redColor);
    background: #faeaea;
}

.bw_card_items span.bw_icon_card.bw_orange {
    color: var(--ogranceColor);
    background: #f7f2ea;
}

.bw_card_items span.bw_icon_card.bw_blue {
    color: var(--blueColor);
    background: #eaf0f7;
}

.bw_card_items h4 {
    font-size: 23px;
    margin: 5px 0;
    font-weight: 600;
    margin-bottom: 10px;
}

.bw_card_items span.bw_trans {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
}

.bw_card_items span.bw_trans.bw_gray {
    color: #666;
}

.bw_card_items span.bw_trans img {
    width: 14px;
    height: 13px;
    margin-right: 5px;
}

.bw_title_card {
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.bw_title_card span.fi {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    background: var(--mainColor);
    margin-right: 7px;
    color: var(--whiteColor);
}

.bw_title_card i {
    font-size: 20px;
    font-weight: normal;
    padding-left: 7px;
    color: var(--redColor);
}

.bw_list_email,
.bw_items_job {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px;
    background: var(--grayColor);
    border-bottom: 1px solid var(--grayColorHover);
    font-size: 14px;
    color: var(--blackColor);
    transition: all 300ms;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.bw_list_email span.bw_start_email,
.bw_items_job span.bw_start_email {
    width: 16px;
    height: 16px;
    display: block;
    cursor: pointer;
    background: url('./../bw_image/icon/i__empty_star.svg') no-repeat center;
    background-size: contain;
    transition: all 300ms;
    margin-right: 10px;
}

.bw_list_email span.bw_start_email.bw_active,
.bw_items_job span.bw_start_email.bw_active,
.bw_list_email span.bw_start_email:hover,
.bw_items_job span.bw_start_email:hover {
    background: url('./../bw_image/icon/i__star.svg') no-repeat center;
}

.bw_list_email .bw_us_email,
.bw_items_job .bw_us_email {
    display: flex;
    align-items: center;
    width: 35%;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--blackColor);
}

.bw_list_email .bw_us_email img,
.bw_items_job .bw_us_email img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 6px;
}

.bw_list_email p,
.bw_items_job p {
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.bw_list_email p a,
.bw_items_job p a {
    color: var(--blackColor);
}

.bw_list_email span.bw_time_email,
.bw_items_job span.bw_time_email {
    font-size: 12px;
    font-style: italic;
    width: calc(20% - 36px);
    text-align: right;
    opacity: 0.7;
}

.bw_list_email.bw_non_read,
.bw_non_read.bw_items_job {
    background: var(--whiteColor);
}

.bw_list_email.bw_non_read .bw_us_email,
.bw_non_read.bw_items_job .bw_us_email,
.bw_list_email.bw_non_read span,
.bw_non_read.bw_items_job span,
.bw_list_email.bw_non_read p,
.bw_non_read.bw_items_job p {
    font-weight: 600;
}

.bw_list_email .bw_active_items,
.bw_items_job .bw_active_items {
    position: absolute;
    right: -100%;
    top: 0;
    bottom: 0;
    display: inline-flex;
    background: var(--whiteColor);
    padding: 0 10px;
    transition: all 300ms;
    align-items: center;
}

.bw_list_email .bw_active_items a,
.bw_items_job .bw_active_items a {
    color: var(--blackColor);
    margin: 5px;
}

.bw_list_email:hover,
.bw_items_job:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.bw_list_email:hover .bw_active_items,
.bw_items_job:hover .bw_active_items {
    right: 0;
}

.bw_list_email.bw_list_notice .bw_us_notice,
.bw_list_notice.bw_items_job .bw_us_notice {
    max-width: 30%;
}

.bw_list_email.bw_list_notice p,
.bw_list_notice.bw_items_job p {
    width: 45%;
}

.bw_list_email.bw_list_notice span.bw_has_file,
.bw_list_notice.bw_items_job span.bw_has_file {
    width: 20px;
    margin-left: 20px;
}

.bw_list_email.bw_list_notice span.bw_time_email,
.bw_list_notice.bw_items_job span.bw_time_email {
    width: calc(25% - 60px);
}

.bw_border_top {
    border-top: 1px solid var(--borderColor);
}

.bw_items_job {
    background: none !important;
    display: block;
    position: relative;
}

.bw_items_job h3 {
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--blackColor);
}

.bw_items_job p {
    display: block;
    margin: 10px 0;
    width: 100%;
    color: var(--blackColor);
}

.bw_items_job .bw_process_20 {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.bw_items_job .bw_process_20 div[role='bw_progressbar'] {
    font-size: 19px;
}

.bw_items_job .bw_comments_job {
    display: inline-flex;
    align-items: center;
    line-height: 14px;
    margin-top: 0;
}

.bw_items_job .bw_comments_job i {
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.bw_items_job .bw_comments_job span.bw_attack {
    padding-left: 20px;
}

.bw_items_job .bw_comments_job span.bw_user_joint {
    margin-left: 20px;
}

.bw_items_job .bw_comments_job span.bw_user_joint img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

.bw_items_job .bw_timline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bw_items_job .bw_timline span {
    font-size: 16px;
    border-radius: 5px;
    font-weight: 500;
    width: 48%;
    line-height: 23px;
    display: block;
    background: url('./../bw_image/icon/d1.svg') no-repeat top 9px left 10px var(--grayColor);
    padding: 8px 15px;
    border: 1px solid var(--grayColor);
}

.bw_items_job .bw_timline span i {
    font-weight: 400;
    display: block;
    font-size: 14px;
    opacity: 0.8;
    padding-left: 20px;
}

.bw_items_job .bw_timline span:last-child {
    background: url('./../bw_image/icon/d2.svg') no-repeat top 9px left 10px var(--grayColor);
}

.bw_items_job .bw_timline.bw_red span {
    border: 1px solid var(--redColor);
}

.bw_items_job .bw_timline.bw_green span {
    border: 1px solid var(--greenColor);
}

.bw_items_job button {
    position: absolute;
    display: inline-flex;
    align-items: center;
    top: 10px;
    border-radius: 50px;
    right: 10px;
}

.bw_items_job button i {
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

p.bw_titleS {
    margin-bottom: 7px;
    font-size: 14px;
}

p.bw_titleS a {
    color: var(--mainColor);
}

.bw_show_child {
    background: var(--secondColor);
    color: var(--whiteColor);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    cursor: pointer;
}

.bw_none {
    display: none;
}

.bw_none.bw_show {
    display: block;
}

.bw_content_notice {
    background: var(--whiteColor);
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.05);
}

.bw_title_notice {
    font-size: 22px;
    font-weight: 700;
}

p.bw_time_send {
    color: #888;
    margin-top: 7px;
    margin-bottom: 20px;
    font-size: 13px;
}

p.bw_time_send i {
    position: relative;
    top: 2px;
}

.bw_item_comments {
    position: relative;
    min-height: 46px;
    padding: 10px 0;
    padding-left: 60px;
}

.bw_item_comments img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0px;
    top: 10px;
}

.bw_item_comments h3 {
    font-weight: 600;
}

.bw_item_comments span {
    font-size: 12px;
    font-style: italic;
    display: inline-block;
    padding: 5px 0;
    margin-bottom: 5px;
}

.bw_item_comments.bw_items_mail {
    padding: 0;
    padding-left: 60px;
}

.bw_item_comments.bw_items_mail img {
    top: 0;
}

.bw_item_comments.bw_items_mail p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.bw_left_mail .bw_send_mail {
    position: sticky;
    top: 10px;
    width: 100% !important;
    margin-bottom: 20px;
    padding: 12px;
    font-size: 15px;
}

.bw_left_mail .bw_send_mail:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.bw_left_mail .bw_send_mail span {
    position: relative;
    top: 2px;
    margin-right: 10px;
}

.bw_action_email {
    padding: 10px;
    top: 78px;
}

.bw_action_email li a {
    width: 100%;
    display: flex;
    position: relative;
    padding: 10px 15px;
    border-radius: 5px;
}

.bw_action_email li a:hover,
.bw_action_email li a.bw_active {
    color: var(--redColor);
    background: var(--grayColorHover);
}

.bw_action_email li a span {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    background: var(--mainColor);
    color: var(--whiteColor);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.bw_action_email li img {
    max-width: 19px;
    margin-right: 10px;
}

.bw_search_email {
    padding: 3px 0;
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 10px;
    background: var(--whiteColor);
    z-index: 22;
}

.bw_search_email button {
    transition: all 300ms;
    padding: 7px 15px;
    border-radius: 7px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    color: var(--mainColor);
    border: 1px solid var(--whiteColor);
    background: var(--whiteColor);
    font-size: 20px;
}

.bw_search_email input {
    transition: all 300ms;
    padding: 7px 15px;
    border-radius: 7px;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: var(--blackColor);
    border: 1px solid var(--whiteColor);
    background: var(--whiteColor);
    width: calc(100% - 55px);
    padding-left: 0;
}

.bw_search_email input::-moz-placeholder {
    opacity: 0.4;
    font-size: 15px;
}

.bw_search_email input::placeholder {
    opacity: 0.4;
    font-size: 15px;
}

.bw_items_email_detail {
    margin-top: 1px;
    margin-bottom: 0 !important;
}

.bw_items_email_detail .bw_collapse {
    padding: 15px;
}

.bw_mail_reply {
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    padding: 15px;
    display: none;
}

.bw_mail_reply .bw_frm_box {
    padding: 10px 0;
}

.bw_mail_reply.bw_show {
    display: block;
}

.bw_form_changePass li {
    display: flex;
    margin: 7px auto;
    align-items: center;
    flex-wrap: wrap;
}

.bw_form_changePass li span {
    width: 30%;
    font-weight: 700;
}

.bw_form_changePass li input {
    width: 70%;
}

.bw_form_changePass li:first-child {
    margin-bottom: 15px;
}

.bw_frm_box .bw_time_s {
    border: 1px solid var(--borderColor) !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background: var(--whiteColor) !important;
}

.bw_frm_box.bw_readonly .bw_time_s {
    background: var(--whiteColor) !important;
}

.bw_green_ac {
    background: #eefffc !important;
}

.bw_note_time {
    padding: 7px 10px;
    background: #eefffc !important;
    border: 1px solid #cee4e0;
    display: inline-flex;
    margin-right: 10px;
    border-radius: 7px;
}

.bw_note_time span {
    width: 20px;
    height: 20px;
    top: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 20px;
    margin-right: 10px;
    color: var(--mainColor);
}

.bw_search_kapan {
    width: 100%;
    padding: 0 20px;
    border-bottom: 1px solid var(--borderColor);
    position: relative;
    z-index: 19;
}

.bw_search_kapan .bw_select {
    max-width: 20%;
    border-right: 1px solid var(--borderColor);
}

.bw_search_kapan .bw_select.bw_select_container--default .bw_select_selection--single {
    border: none;
}

.bw_search_kapan .bw_select.bw_select_container--default .bw_select_selection--single .bw_select_selection__rendered {
    padding-left: 0;
    padding-right: 20px;
}

.bw_search_kapan .bw_select.bw_select_container--default .bw_select_selection--single .bw_select_selection__arrow {
    right: 10px;
}

.bw_search_kapan .bw_search_email {
    width: 100%;
    max-width: calc(80% - 260px);
    border-bottom: none;
}

.bw_search_kapan .bw_search_email button span {
    position: relative;
    top: 2px;
}

.bw_search_kapan a.bw_btn_success {
    width: 180px;
}

.bw_search_kapan .bw_seting_job {
    width: 80px;
    display: flex;
    align-items: center;
    padding: 0 7px;
    justify-content: space-between;
}

.bw_search_kapan .bw_seting_job a {
    width: 31px;
    height: 31px;
}

.bw_search_kapan .bw_seting_job a span {
    margin-right: 0 !important;
}

.bw_content_kapan {
    height: calc(100vh - 110px);
}

.bw_main_kapan {
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.bw_main_kapan .bw_wrap_kapan {
    padding: 20px 10px;
    height: 100%;
    width: a;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status {
    width: 390px;
    background: #f2f2f2;
    height: 100%;
    margin-right: 10px;
    vertical-align: top;
    display: inline-block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status {
    border-top: 4px solid var(--mainColor);
    border-bottom: 1px solid var(--borderColor);
    padding: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status span.bw_close_box {
    width: 16px;
    height: 16px;
    background: url('./../bw_image/icon/i__caret_right.svg') no-repeat center;
    background-size: 16px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status h3 {
    width: calc(100% - 53px);
    margin-left: 7px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status h3 span {
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 50px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status a.bw_add_job {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--mainColor);
    color: var(--whiteColor);
    cursor: pointer;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_title_box_status a.bw_add_job span {
    font-size: 25px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs {
    width: 100%;
    position: relative;
    height: calc(100% - 61px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 13px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs .bw_items_job_detail {
    background: var(--whiteColor) !important;
    border-radius: 5px;
    margin-bottom: 10px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs .bw_items_job_detail h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs .bw_items_job_detail p {
    margin-top: 5px;
    text-overflow: unset;
    white-space: unset;
    font-size: 15px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs .bw_items_job_detail .bw_timline span {
    padding: 8px 10px;
    font-size: 14px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status .bw_list_jobs .bw_items_job_detail .bw_timline span i {
    padding-left: 25px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status.bw_close_wrap {
    max-width: 60px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status.bw_close_wrap .bw_list_jobs {
    display: none;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status.bw_close_wrap .bw_title_box_status {
    display: flex;
    align-items: flex-start;
    border-bottom: none;
    flex-direction: column;
    height: 100%;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status.bw_close_wrap .bw_title_box_status span.bw_close_box {
    transform: rotate(180deg);
    width: 100%;
    height: 29px;
}

.bw_main_kapan .bw_wrap_kapan .bw_box_status.bw_close_wrap .bw_title_box_status h3 {
    transform: rotate(90deg);
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
}

.bw_main_kapan.bw_non_show {
    display: none;
}

.bw_has_pt,
.bw_tramay {
    display: none;
}

.bw_has_pt.bw_show,
.bw_tramay.bw_show {
    display: block;
}

.bw_notes_recipts {
    margin-top: 10px;
    padding-right: 30px;
}

.bw_notes_recipts li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px dashed var(--borderColor);
}

.bw_notes_recipts li:first-child b {
    font-size: 17px;
    color: var(--mainColor);
}

.bw_count_cus.bw_cou_report {
    display: flex;
    width: 100%;
    margin-right: 0;
    flex-wrap: wrap;
}

.bw_count_cus.bw_cou_report span {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.bw_count_cus.bw_cou_report span img {
    width: 20px;
    height: 20px;
    margin: 0;
}

.bw_count_cus.bw_cou_report span.bw_green {
    background: var(--mainColor);
}

.bw_count_cus.bw_cou_report span.bw_blue {
    background: var(--blueColor);
}

.bw_count_cus.bw_cou_report span.bw_red {
    background: var(--redColor);
}

.bw_count_cus.bw_cou_report span.bw_ogrance {
    background: var(--ogranceColor);
}

.bw_count_cus.bw_cou_report span.bw_black {
    background: var(--blackColor);
}

.bw_count_cus.bw_cou_report div {
    padding-left: 10px;
}

.bw_count_cus.bw_cou_report div h4 {
    font-size: 17px;
    font-weight: 600;
}

.bw_count_cus.bw_cou_report div p {
    font-size: 12px;
}

.bw_show_date {
    position: relative;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.bw_show_date span {
    padding-right: 10px;
    color: var(--mainColor);
    position: relative;
    top: 2px;
}

.bw_show_date::after {
    border-color: #666 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    right: 10px;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    content: '';
    top: 50%;
    width: 0;
}

.bw_choose_inp {
    background: #fff;
    padding: 15px;
    position: absolute;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
    z-index: 22;
    width: 550px;
    right: 0;
    display: none;
}

.bw_choose_inp .calendar-head,
.bw_choose_inp .calendar-content span.calendar_active {
    background-color: var(--mainColor) !important;
}

.bw_choose_inp .calendar-content span.calendar_inRange {
    background-color: #8addd1;
}

.bw_choose_inp h4 {
    font-weight: 600;
    color: #212121;
    font-size: 18px;
}

.bw_choose_inp a {
    padding: 7px 16px;
    color: #fff;
    background: var(--mainColor);
    display: inline-block;
    margin-top: 10px;
}

.bw_choose_inp .bw_show_calendar input {
    position: absolute;
    opacity: 0;
    width: 1;
}

.bw_number_tu {
    background: var(--grayColor);
    padding: 20px;
    text-align: center;
    font-size: 17px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bw_number_tu b {
    font-size: 24px;
    color: var(--mainColor);
    padding-left: 20px;
}

.bw_title_page {
    font-weight: bold;
}

.bw_title_search {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.bw_show_more_method {
    display: none;
}

.bw_setting_button {
    width: 100%;
}

.bw_search_box {
    background: var(--grayColor);
    padding: 15px;
}

.bw_btn_more_search {
    cursor: pointer;
    font-size: 14px;
    color: #333;
    padding-left: 10px;
}

.bw_more_search {
    display: none;
}

.bw_enter_s {
    width: 100% !important;
}

.bw_btn_add_imei {
    padding: 3px;
    border: 1px solid var(--borderColor);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.bw_btn_add_imei:hover {
    background: var(--whiteColor);
}

.bw_btn_add_imei span {
    margin: 0;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.bw_dchuyen,
.bw_baohanh,
.bw_banhang,
.bw_trancc,
.bw_cbk {
    display: none;
}

.bw_footer_card {
    padding: 20px 0;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bw_footer_card span {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--mainColor);
}

.bw_number_notice {
    width: 26px;
    display: inline-block;
}

.bw_file_notice a {
    margin-top: 20px;
    padding: 15px;
    display: inline-flex;
    align-items: center;
    background: var(--grayColor);
    color: var(--mainColor);
    font-weight: 600;
}

.bw_file_notice a span {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mainColor);
}

#bw_percent {
    display: none;
}

.bw_show_tip {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.bw_show_tip input {
    padding-right: 70px;
}

.bw_type_mon {
    width: 70px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    right: 6px;
}

.bw_type_mon label {
    width: 33px;
    display: flex;
    align-items: center;
    margin-right: -1px;
    border-radius: 0;
}

.bw_type_mon label span {
    width: 33px;
    height: 33px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    color: var(--blackColor);
    border-radius: 0 !important;
}

.bw_type_mon label input:checked~span {
    color: var(--whiteColor);
}

.bw_type_mon label input:checked~span::after {
    display: none;
}

.bw_type_mon label:last-child span {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.bw_news_items {
    border: 1px solid var(--borderColor);
    border-top: none;
    transition: all 300ms;
    display: block;
    color: var(--blackColor);
    border-radius: 5px;
    overflow: hidden;
}

.bw_news_items img {
    max-width: 100%;
    width: 100%;
}

.bw_news_items h3 {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

.bw_news_items p {
    font-size: 13px;
    padding: 10px 20px;
    padding-top: 0;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bw_news_items button.bw_btn {
    margin: 20px;
    margin-top: 10px;
    font-size: 13px;
    padding: 5px 10px;
    border: none;
}

.bw_news_items:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.bw_news_items:hover button.bw_btn {
    background: var(--secondColor);
}

.bw_creater {
    padding: 10px 20px;
    border-bottom: 1px solid var(--borderColor);
}

.bw_creater img {
    max-width: 24px;
}

.bw_creater h4 {
    color: var(--mainColor);
    padding: 0 7px;
    font-weight: 500;
    font-size: 13px;
}

.bw_creater span {
    opacity: 0.5;
    color: var(--blackColor);
    font-size: 13px;
}

.bw_title_news_detail {
    font-size: 34px;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    max-width: 80%;
    margin: 10px auto;
}

.bw_main_content_news {
    padding: 30px 0;
}

.bw_search_border {
    border-bottom: 1px solid var(--borderColor);
    padding: 10px;
}

.bw_search_border .bw_box_search_notice {
    max-width: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.bw_search_border .bw_box_search_notice input {
    max-width: 320px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.bw_search_border .bw_box_search_notice button {
    max-width: 110px;
    width: 100%;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid var(--mainColor);
}

.bw_main_notice {
    height: calc(100vh - 220px);
}

.bw_list_notice_de {
    width: 25%;
    height: 100%;
    overflow-y: auto;
}

.bw_detail_notice {
    width: 75%;
    border-left: 1px solid var(--borderColor);
    padding: 30px 50px;
    padding-right: 30px;
    height: 100%;
    overflow-y: auto;
}

.bw_notice_items {
    position: relative;
    padding: 15px 20px;
    border-bottom: 1px dashed var(--borderColor);
    padding-left: 60px;
    display: block;
    color: var(--blackColor);
}

.bw_notice_items img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 10px;
    top: 15px;
}

.bw_notice_items h3 {
    font-size: 14px;
    font-weight: 500;
}

.bw_notice_items h3 span {
    font-weight: normal;
    font-size: 12px;
    color: var(--blackColor);
    opacity: 0.7;
    font-style: italic;
}

.bw_notice_items h2 {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0;
    line-height: 23px;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.bw_notice_items p {
    font-size: 12px;
    color: var(--mainColor);
}

.bw_notice_items p span {
    position: relative;
    top: 1px;
}

.bw_notice_items.bw_active {
    background: var(--grayColor);
}

.bw_notice_items.bw_active h2 {
    font-weight: 600;
}

.bw_no_padding_b {
    padding-bottom: 0;
}

.bw_info_wod li {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.bw_info_wod li span {
    width: 50%;
}

.bw_info_wod li b {
    width: 30%;
}

.bw_comment_box .bw_title_page {
    font-size: 17px;
    margin-bottom: 10px;
}

.bw_comment_box .bw_frm_box {
    padding: 7px 0;
    border-bottom: 1px dashed var(--borderColor);
}

.bw_comment_box .bw_frm_box:nth-child(2) {
    border-bottom: none;
}

.bw_ap {
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    font-style: italic;
}

span.bw_text_inp {
    position: absolute;
    z-index: 1;
    right: 10px;
    bottom: 10px;
    padding: 7px 10px;
    border-left: 1px solid var(--borderColor);
}

.bw_count_w {
    padding: 10px 20px;
    background: var(--grayColor);
    height: 100%;
}

.bw_count_w h3 {
    font-size: 21px;
    font-weight: 600;
}

.bw_count_w a {
    font-size: 13px;
    padding-top: 7px;
    margin-top: 10px;
    cursor: pointer;
    color: var(--blackColor);
    opacity: 0.7;
    display: block;
    border-top: 1px solid var(--borderColor);
}

.bw_count_w a:hover {
    opacity: 1;
    color: var(--redColor);
}

#bw_vat_div {
    display: none;
}

#bw_vat_div.bw_show {
    display: block !important;
}

.bw_main_404 {
    height: calc(100vh - 160px);
}

.bw_main_404 h2 {
    font-size: 40px;
    font-weight: 700;
}

.bw_main_404 img {
    max-height: 220px;
    margin-bottom: 20px;
}

.bw_main_404 p {
    font-size: 17px;
    margin-top: 20px;
}

.bw_logout {
    padding-left: 10px;
    display: inline-block;
    color: var(--mainColor);
}

.bw_box_list_card .bw_title_list_card {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
}

.bw_box_list_card ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0;
}

.bw_box_list_card a {
    display: block;
    padding: 10px 0;
    padding-bottom: 5px;
    color: var(--mainColor);
    border-top: 1px solid var(--borderColor);
    margin-top: 5px;
}


/* #region phone */

@keyframes scalePhone {
    0% {
        transform: scale(0.9, 0.9);
    }
    50% {
        transform: scale(1.15, 1.15);
    }
    100% {
        transform: scale(0.9, 0.9);
    }
}

.bw_btn_callS {
    position: fixed;
    bottom: 120px;
    right: 30px;
    z-index: 20;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    animation-name: scalePhone;
    animation-duration: 700ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.bw_btn_closeCall {
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 20;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    animation-name: scalePhone;
    animation-duration: 700ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.bw_btn_closeCall a {
  cursor: pointer;
}
.bw_btn_closeCall a img {
  max-width: 50px;
}

.bw_btn_callS a {
    cursor: pointer;
}

.bw_btn_callS a img {
    max-width: 50px;
}

.bw_btn_call_order {
  position: fixed;
  bottom: 200px;
  right: 30px;
  z-index: 20;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  animation-name: scalePhone;
  animation-duration: 700ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bw_btn_call_order a {
  cursor: pointer;
}

.bw_btn_call_order a img {
  max-width: 50px;
}

.bw_box_iphone {
    width: 250px;
    height: 490px;
    background: var(--whiteColor);
    border-radius: 30px;
    box-shadow: 0 0 30px rgba(212, 161, 161, 0.25);
    position: fixed;
    bottom: 150px;
    right: 30px;
    z-index: 22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    transition: all 400ms;
}

.bw_box_iphone .bw_list_customer {
    padding: 25px;
    padding-bottom: 0;
    height: 100%;
    max-height: calc(100% - 61px);
}

.bw_box_iphone .bw_list_customer h3 {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 10px;
}

.bw_box_iphone .bw_list_customer ul {
    height: 100%;
    max-height: calc(100% - 35px);
    overflow-y: auto;
    padding-right: 10px;
}

.bw_box_iphone .bw_list_customer ul li {
    position: relative;
    padding: 7px 0;
    border-top: 1px dashed var(--borderColor);
}

.bw_box_iphone .bw_list_customer ul li h4 {
    font-weight: 600;
}

.bw_box_iphone .bw_list_customer ul li span {
    font-size: 12px;
    color: var(--blueColor);
    opacity: 0.6;
}

.bw_box_iphone .bw_list_customer ul li a.bw_callBtn {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
}

.bw_box_iphone .bw_list_customer ul li a.bw_callBtn img {
    max-width: 100%;
}

.bw_box_iphone .bw_list_customer ul::-webkit-scrollbar {
    width: 5px;
    position: absolute;
}

.bw_box_iphone .bw_list_customer ul::-webkit-scrollbar-track {
    background: var(--grayColor);
}

.bw_box_iphone .bw_list_customer ul::-webkit-scrollbar-thumb {
    background: var(--borderColor);
    border-radius: 4px;
}

.bw_box_iphone .bw_changeMethodCal {
    width: 100%;
    border-top: 1px solid var(--borderColor);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bw_box_iphone .bw_changeMethodCal a {
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
}

.bw_box_iphone .bw_changeMethodCal a img {
    max-width: 40px;
}

.bw_box_iphone .bw_numberPhone {
    height: 100%;
    max-height: 33%;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blackColor);
}

.bw_box_iphone .bw_numberPhone input {
    width: 100%;
    padding: 10px 0;
    font-size: 27px;
    letter-spacing: 1px;
    text-align: center;
    text-align: center;
    color: var(--blackColor);
    border: none;
    outline: none;
}

.bw_box_iphone .bw_numberPhone input::-moz-placeholder {
    opacity: 0.45;
}

.bw_box_iphone .bw_numberPhone input::placeholder {
    opacity: 0.45;
}

.bw_box_iphone .bw_btnNumer {
    height: 100%;
    max-height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 10px 30px;
}

.bw_box_iphone .bw_btnNumer a.bw_itemsNumber {
    width: 40px;
    height: 40px;
    margin: 3px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 50%;
    border: 1px solid var(--borderColor);
    cursor: pointer;
    transition: all 300ms;
}

.bw_box_iphone .bw_btnNumer a.bw_itemsNumber:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.bw_box_iphone .bw_btnNumer a.bw_itemsNumber img {
    height: 20px;
    width: 20px;
}

.bw_box_iphone .bw_ft_call {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bw_box_iphone .bw_ft_call .bw_callNow {
    height: 100%;
    max-height: 20%;
    cursor: pointer;
    padding-top: 10px;
}

.bw_box_iphone .bw_ft_call .bw_callNow img {
    max-width: 50px;
    margin-bottom: 30px;
}

.bw_box_iphone.bw_show {
    display: block;
}

.bw_content_call {
    text-align: center;
    transition: all 300ms;
}

.bw_content_call p {
    font-size: 18px;
    color: var(--whiteColor);
    margin-bottom: 20px;
    opacity: 0.7;
    transition: all 300ms;
}

.bw_content_call img.bw_imgNumber {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    transition: all 300ms;
    border-radius: 50%;
}

.bw_content_call h3 {
    font-size: 24px;
    color: var(--whiteColor);
    margin-bottom: 15px;
    transition: all 300ms;
}

.bw_content_call .bw_endCall {
    position: fixed;
    bottom: 40px;
    /* left: calc(50vw - 25px); */
    top: 70vh;
    left: calc(10vw + 2.5vw);
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 300ms;
}

.bw_content_call .bw_btn_call {
    position: fixed;
    bottom: 40px;
    left: calc(50vw + 65px);
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 300ms;
    z-index: 10000;
}

.bw_content_call .bw_endCall img {
    max-width: 50px;
    transition: all 300ms;
}

.bw_content_call .bw_btn_call img {
    max-width: 50px;
    transition: all 300ms;
}

.bw_content_call .bw_btn_scale_down {
  position: fixed;
  bottom: 40px;
  /* left: calc(50vw - 115px); */
  top: 70vh;
  left: calc(10vw - 5.5vw);
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 300ms;
}

.bw_content_call .bw_btn_scale_down img {
  max-width: 50px;
  transition: all 300ms;
}

#bw_calling,
#bw_reCalling,
#bw_enjoyCalling {
    background: rgba(0, 0, 0, 0.8);
    transition: all 300ms;
}

#bw_calling.bw_lessCalling,
.bw_lessCalling#bw_reCalling,
.bw_lessCalling#bw_enjoyCalling {
    height: 60px;
}

#bw_calling.bw_lessCalling .bw_content_call,
.bw_lessCalling#bw_reCalling .bw_content_call,
.bw_lessCalling#bw_enjoyCalling .bw_content_call {
    width: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

#bw_calling.bw_lessCalling .bw_content_call img.bw_imgNumber,
.bw_lessCalling#bw_reCalling .bw_content_call img.bw_imgNumber,
.bw_lessCalling#bw_enjoyCalling .bw_content_call img.bw_imgNumber {
    display: none;
}

#bw_calling.bw_lessCalling .bw_content_call p,
.bw_lessCalling#bw_reCalling .bw_content_call p,
.bw_lessCalling#bw_enjoyCalling .bw_content_call p {
    font-size: 13px;
    margin-bottom: 0;
}

#bw_calling.bw_lessCalling .bw_content_call h3,
.bw_lessCalling#bw_reCalling .bw_content_call h3,
.bw_lessCalling#bw_enjoyCalling .bw_content_call h3 {
    font-size: 20px;
    margin-bottom: 0;
}

#bw_calling.bw_lessCalling .bw_content_call .bw_endCall,
.bw_lessCalling#bw_reCalling .bw_content_call .bw_endCall,
.bw_lessCalling#bw_enjoyCalling .bw_content_call .bw_endCall {
    width: 40px;
    height: 40px;
    position: unset;
}

#bw_calling.bw_lessCalling .bw_content_call .bw_endCall img,
.bw_lessCalling#bw_reCalling .bw_content_call .bw_endCall img,
.bw_lessCalling#bw_enjoyCalling .bw_content_call .bw_endCall img {
    max-width: 40px;
}

#bw_enjoyCalling .bw_content_call p {
    color: var(--greenColor);
}

.bw_mofal_ft {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
}

.bw_mofal_ft .bw_endCall {
    position: unset;
    margin: 0 20px;
}

.hidden_button__calling {
    display: none;
}


/* #endregion */

.bw_inf_cus {
    position: fixed;
    background: var(--whiteColor);
    right: 0;
    top: 0;
    bottom: 0;
    padding: 30px;
    /* width: 600px; */
    width: 70vw;
    transition: all 300ms;
    overflow: scroll;
}

.bw_inf_cus h3 {
    font-size: 18px;
    font-weight: 600;
}

.bw_inf_cus .bw_inf_customer {
    margin-top: 15px;
    flex-wrap: wrap;
}

.bw_inf_cus .bw_inf_customer p {
    font-size: 14px;
    line-height: 18px;
}

.bw_inf_cus .bw_inf_customer img.bw_imgUser {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.bw_inf_cus .bw_inf_customer .bw_in {
    width: calc(100% - 70px);
    margin-left: 10px;
}

.bw_inf_cus .bw_inf_customer .bw_in h4 {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.bw_inf_cus .bw_inf_customer .bw_in h4 span {
    font-size: 12px;
    font-weight: normal;
    padding: 3px 6px;
    margin-left: 7px;
}

.bw_inf_cus .bw_notUser {
    border: 1px dashed var(--borderColor);
    background: var(--grayColor);
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 5px 10px;
}

.bw_inf_cus .bw_notUser p {
    font-size: 13px;
    margin-bottom: 7px;
}

.bw_main_history {
    margin-top: 20px;
    padding-bottom: 140px;
    display: none;
}

.bw_main_history ul {
    position: relative;
}

.bw_main_history ul::after {
    width: 1px;
    height: 100%;
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    /* background: var(--borderColor); */
}

.bw_main_history ul li {
    margin-left: 30px;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
}

.bw_main_history ul li::after {
    width: 20px;
    height: 20px;
    background: url(../bw_image/i__play.svg) no-repeat center;
    background-size: contain;
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
}

.bw_main_history ul li a {
    color: var(--blueColor);
    font-size: 17px;
    display: block;
    margin-top: 5px;
    font-style: italic;
}

.bw_main_history.bw_show {
    display: block;
}

#bw_show_d {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: var(--mainColor);
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: -40px;
    top: 20px;
}

.bw_tabs_title span {
    padding-right: 10px;
    cursor: pointer;
    opacity: 0.5;
}

.bw_tabs_title span.bw_active {
    opacity: 1;
}

.bw_add_note {
    background: var(--grayColor);
    padding: 15px;
    margin-bottom: 20px;
}

.bw_main_history ul .bw_history__note::after {
    width: 20px;
    height: 20px;
    background: url(../bw_image/i_historyNote.svg) no-repeat center;
    background-size: contain;
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
}

.bw_content_call .bw_transferCall {
    position: fixed;
    bottom: 40px;
    /* left: calc(50vw + 65px); */
    top: 70vh;
    left: calc(10vw + 10vw);
    cursor: pointer;
}

.bw_transfer_call_modal {
    z-index: 10000;
    margin-right: 770px;
}

.bw_transfer_btnNumer {
    width: 40px;
    height: 40px;
    margin: 3px 10px 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 50%;
    border: 1px solid var(--borderColor);
    cursor: pointer;
    transition: all 300ms;
    cursor: pointer;
}

.bw_btn_transferCallS {
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 20;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
}

.bw_btn_transferCallS a {
    cursor: pointer;
}

.bw_btn_transferCallS a img {
    max-width: 50px;
}

.bw_box_social {
    flex-wrap: wrap;
    display: flex;
}

.bw_control_soc {
    padding: 10px 0;
    border-bottom: 1px solid var(--borderColor);
}

.bw_control_soc .bw_control_social {
    display: flex;
    align-items: center;
}

.bw_control_soc .bw_control_social li a {
    padding: 7px 13px;
    font-size: 15px;
    background: var(--grayColor);
    border-radius: 3px;
    margin-right: 10px;
    display: inline-flex;
    cursor: pointer;
}

.bw_control_soc .bw_control_social li.bw_active a {
    background: var(--mainColor);
    color: var(--whiteColor);
}

.bw_main_notice {
    height: calc(100vh - 155px);
}

.bw_left_social {
    width: calc(70% - 10px);
    padding: 0 10px;
}

.bw_left_social .bw_search_mes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    z-index: 22;
    background-color: var(--whiteColor);
    flex-wrap: wrap;
}

.bw_left_social .bw_search_mes form {
    width: 85%;
    position: relative;
}

.bw_left_social .bw_search_mes form button {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    font-size: 16px;
    right: 7px;
    top: 7px;
}

.bw_left_social .bw_search_mes a {
    width: 15%;
    text-align: center;
    font-size: 16px;
    color: var(--blackColor);
    cursor: pointer;
}

.bw_right_social {
    width: 30%;
    margin-left: 10px;
    padding: 0 10px;
    max-height: calc(100vh - 90px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background: var(--whiteColor);
}

.bw_right_social::-webkit-scrollbar {
    display: none;
}

.bw_right_social h3:not(.bw_collapse h3) {
    font-size: 19px;
    font-weight: 600;
    line-height: 65px;
    border-bottom: 1px solid var(--borderColor);
}

.bw_right_social .bw_collapse {
    padding: 10px 12px;
    margin-top: 10px;
}

.bw_right_social .bw_collapse .bw_collapse_title span {
    transform: rotate(0) !important;
}

.bw_right_social .bw_collapse .bw_table {
    font-size: 13px;
}

.bw_right_social .bw_collapse .bw_table th {
    background: var(--borderColor);
    color: var(--blackColor);
    padding: 10px;
}

.bw_right_social .bw_collapse .bw_table tr:last-child td {
    border-bottom: none;
    padding: 10px;
}

.bw_right_social .bw_total_moneys {
    font-size: 14px;
    margin-top: 7px;
}

.bw_right_social .bw_list_label {
    overflow: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
}

.bw_right_social .bw_choose_label {
    max-width: 50%;
    width: 100%;
    margin: 5px 0;
    float: left;
    font-size: 12px;
}

.bw_right_social .bw_choose_label i {
    display: inline-block;
    align-items: center;
    justify-content: center;
    color: var(--whiteColor);
    font-size: 12px;
    padding: 2px 7px;
    border-radius: 3px;
}

.bw_right_social .bw_add_cart {
    margin-bottom: 30px;
    width: 70%;
    font-size: 15px;
    font-weight: 600;
}

.bw_right_social .bw_formAddCart {
    position: absolute;
    left: 100%;
    right: 0;
    top: 0;
    width: 100%;
    background: var(--whiteColor);
    z-index: 22;
    padding: 0 10px;
    transition: all 300ms;
}

.bw_right_social .bw_formAddCart h5 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 7px;
}

.bw_right_social .bw_formAddCart p {
    font-size: 13px;
    line-height: 17px;
}

.bw_right_social .bw_formAddCart .bw_fils {
    flex-wrap: wrap;
    margin-top: 5px;
}

.bw_right_social .bw_formAddCart .bw_fils input {
    width: 70%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #aaa;
}

.bw_right_social .bw_formAddCart .bw_fils button {
    width: 30%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #aaa;
    border: 1px solid #aaa;
}

.bw_right_social .bw_formAddCart .bw_table_pro table {
    font-size: 13px;
    white-space: unset;
}

.bw_right_social .bw_formAddCart .bw_table_pro table th {
    background: var(--borderColor);
    color: var(--blackColor);
    font-weight: 600;
}

.bw_right_social .bw_formAddCart .bw_table_pro table tr {
    white-space: unset;
}

.bw_right_social .bw_formAddCart .bw_table_pro table tr td .bw_inp {
    max-width: 70px !important;
    min-width: 70px;
}

.bw_right_social .bw_formAddCart .bw_table_pro table tr td a {
    font-size: 13px;
}

.bw_right_social .bw_formAddCart .bw_table_pro table tr td a.bw_delProduct i {
    color: var(--redColor);
    font-size: 15px;
}

.bw_right_social .bw_formAddCart .bw_table_pro table tr td i {
    font-style: italic;
    display: inline-block;
    margin-top: 5px;
    color: var(--borderMenuColor);
    font-size: 12px;
    opacity: 0.7;
}

.bw_right_social .bw_formAddCart.bw_show {
    left: 0;
}

.bw_chat_items a.bw_notice_items {
    color: var(--blackColor) !important;
}

.bw_chat_items a.bw_notice_items p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    color: var(--blackColor);
    opacity: 0.7;
}

.bw_chat_items a.bw_notice_items p.bw_step span {
    padding: 2px 3px;
    border-radius: 2px;
    font-size: 12px;
    background: var(--borderColor);
    display: inline-flex;
    border: 1px solid var(--borderColor);
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    color: var(--blackColor);
}

.bw_chat_items a.bw_notice_items.bw_active p {
    font-weight: 500;
    opacity: 1;
}

.bw_choose_page {
    position: relative;
    width: 270px;
    background-image: var(--whiteColor);
    border: 1px solid var(--borderColor);
    padding: 7px 15px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.bw_choose_page img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.bw_list_page {
    background: var(--whiteColor);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    display: none;
}

.bw_list_page .bw_items_page {
    padding: 7px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: var(--blackColor);
    font-weight: normal;
}

.bw_list_page .bw_items_page img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.bw_list_page .bw_last_items {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-top: 10px;
    border-top: 1px solid var(--borderColor);
    color: var(--blackColor);
    opacity: 0.7;
    margin-top: 10px;
}

.bw_list_page .bw_last_items span {
    padding-right: 10px;
}

.bw_list_page.bw_show {
    display: block;
}


/* #region: bw_contain_mess */

.bw_contain_mess {
    background: url(../bw_image/bg_chat.png) no-repeat bottom center var(--grayColor);
    background-size: contain;
    height: 100%;
    border-left: 1px solid var(--borderColor);
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.bw_contain_mess .bw_detail_inf {
    background: var(--whiteColor);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    padding: 7px 10px;
    overflow: hidden;
    width: 100%;
    height: 57px;
}

.bw_contain_mess .bw_detail_inf img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 7px;
    float: left;
}

.bw_contain_mess .bw_detail_inf h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}

.bw_contain_mess .bw_detail_inf p.bw_step span {
    padding: 1px 2px;
    border-radius: 2px;
    font-size: 11px;
    background: var(--borderColor);
    display: inline-flex;
    border: 1px solid var(--borderColor);
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    color: var(--blackColor);
    margin-top: 2px;
}

.bw_contain_mess .bw_chat_box {
    background: var(--whiteColor);
    padding: 10px;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bw_contain_mess .bw_chat_box img {
    max-width: 35px;
    max-height: 35px;
    border-radius: 50%;
}

.bw_contain_mess .bw_chat_box .bw_icon_group {
    max-width: 80px;
}

.bw_contain_mess .bw_chat_box .bw_icon_group a {
    font-size: 17px;
    color: var(--blackColor);
    display: inline-block;
    margin: 0 3px;
    opacity: 0.7;
    transition: all 300ms;
}

.bw_contain_mess .bw_chat_box .bw_icon_group a:hover {
    opacity: 1;
}

.bw_contain_mess .bw_chat_box textarea {
    width: 100%;
    max-width: calc(100% - 135px);
    outline: none;
    border: none;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    height: 40px;
    padding: 10px;
    text-overflow: ellipsis;
    line-height: 18px;
}


/* #endregion: bw_contain_mess */


/* #region: mainChat */

.bw_main_chat {
    padding: 15px;
    height: calc(100% - 117px);
    overflow: hidden;
    overflow-y: auto;
}

.bw_main_chat .bw_text_date {
    text-align: center;
    font-size: 12px;
    opacity: 0.7;
}

.bw_main_chat::-webkit-scrollbar {
    display: none;
}

.bw_mainChatItems {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.bw_mainChatItems img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    left: 0;
}

.bw_mainChatItems .bw_textChat {
    margin-left: 33px;
    width: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.bw_mainChatItems .bw_textChat span {
    background: var(--whiteColor);
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    display: inline-block;
    font-size: 15px;
    margin-top: 7px;
    line-height: 19px;
    position: relative;
}

.bw_mainChatItems .bw_textChat span:hover i.bw_chatEmoji {
    display: flex;
}

.bw_mainChatItems .bw_textChat i:not(.bw_chatEmoji) {
    font-size: 11px;
    opacity: 0.7;
    display: inline-block;
    margin-top: 5px;
}

.bw_mainChatItems .bw_textChat i.bw_chatEmoji {
    font-size: 11px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    background: var(--whiteColor);
    border: 1px solid var(--borderColor);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    bottom: -9px;
    right: 10px;
    color: var(--borderMenuColor);
    display: none;
}

.bw_mainChatItems.bw_chatActive {
    justify-content: flex-end;
}

.bw_mainChatItems.bw_chatActive .bw_textChat {
    align-items: flex-end;
}

.bw_mainChatItems.bw_chatActive .bw_textChat span {
    background: var(--blueColor);
    color: var(--whiteColor);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0px;
}

.btn_workflow_calling, 
.btn_workflow_calling_active,
.btn_check_price,
.btn_check_stock {
    display: inline-block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 0%, rgba(228, 228, 228, 1) 100%, rgba(255, 255, 255, 1) 100%);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgba(226, 226, 226, 1);
    font-family: Arial, sans-serif;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #000
}
.btn_workflow_calling_active{
    background: rgba(32, 168, 216, 1);
    color: #FDFDFD;
}
.btn_check_price{
    background: inherit;
    background-color: rgba(255, 236, 37, 1);
    font-family: 'Helvetica', sans-serif;
}
.btn_check_stock{
    background: inherit;
    background-color: rgba(32, 168, 216, 1);
    font-family: 'Helvetica', sans-serif;
    color: #FDFDFD;
}

/* css button tab calling */
.bw_tab_calling,
.bw_tab_calling_active {
    display: inline-block;
    margin: 0px 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 0%, rgba(228, 228, 228, 1) 100%, rgba(255, 255, 255, 1) 100%);
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid;
    border-color: rgba(226, 226, 226, 1);
    box-shadow: none;
    font-family: 'Helvetica-Bold', 'Helvetica Bold', 'Helvetica', sans-serif;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    color: #000;
}
.bw_tab_calling_active {
    background: inherit;
    background-color: rgba(32, 168, 216, 1);
    color: #FDFDFD;
}

.bw_btn_add_customer,
.bw_btn_save_work_flow,
.bw_btn_save_note{
    border-width: 0px;
    width: 85px;
    height: 34px;
    background: inherit;
    background-color: rgba(32, 168, 216, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #FDFDFD;
    cursor: pointer;
}
.bw_btn_save_work_flow{
    width: 100%;
    margin-top: 95px;
    height: 40px;
}
.text_valiadte{
    font-size: 9px;
    color: red;
}
/* css button tab calling */

.bw_lb_sex_padding {
    padding: 4px 0 !important;
    flex-wrap: wrap;
}
.bw_btn_save_note{
    width: 80px;
}

.bw_Evaluate_call{
    position: fixed;
    bottom: 40px;
    top: 90vh;
    left: -30px;
    width: 200px;
    height: 50px;
    transition: all 300ms;
    color: #FFF;
    font-size: 13px;
}
.bw_btn_full_name {
    margin-left: 5px;
    height: 24px;
    background: #1677ff;
    border: none;
    color: #FFF;
    border-radius: 5px;
    cursor: pointer;
}

.stocks_out_request_code div,
.purchase_order_code div {
    pointer-events: all;
}
/* #endregion: mainChat */

/* Css Báo cáo */
.rp_fill {
    position: fixed;
    padding: 15px;
    width: 60vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: #FFF;
    overflow: auto;
}
.bw_frm_box_rp_fill{
    background: var(--whiteColor);
    width: 50%;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 1px solid silver;
}
.rp_btn_report,.rp_btn_cancel,.rp_btn_clear{
    padding: 10px;
    background: #20A8D8;
    color: #FFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.rp_btn_cancel,.rp_btn_clear{
    background: #C8CED3;
    color: #000;
}

.report-btn{
    position: fixed;
    justify-content: space-between;
    bottom: 10px;
    z-index: 10000;
    width: 57.2%;
    background-color: #FFFFFF;
}
/* Css Báo cáo */