.antd-pro-components-sider-menu-index-logo {
  position: relative;
  height: 60px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 60px;
  background: #0B2447;
  transition: all 0.3s;
  border-bottom: 1px solid #265b61;
}

.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}

.antd-pro-components-sider-menu-index-logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.antd-pro-components-global-header-index-header {
  position: relative;
  height: 60px;
  padding: 0;
  background-color: #f8f8f8;
}

.antd-pro-components-global-header-index-trigger {
  height: 64px;
  padding: 22px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}

.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: 20px 8px 20px 0;
  color: #1890ff;
  vertical-align: top;
  background: hsla(0, 0%, 100%, 0.85);
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.ant-avatar-image {
  background: transparent;
}

.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.ant-avatar>img {
  display: block;
  width: 100%;
  height: 100%;
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: 20px 8px 20px 0;
  color: #1890ff;
  vertical-align: top;
  background: hsla(0, 0%, 100%, 0.85);
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.ant-avatar-image {
  background: transparent;
}

.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}

/* .antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
} */

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  -webkit-transform: translateZ(0);
}

.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-dropdown-menu-item .anticon:first-child,
.ant-dropdown-menu-submenu-title .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu i {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-submenu-open>.ant-menu-submenu-title {
  color: #fff !important;
}

.ant-breadcrumb-link a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}

.ant-breadcrumb-link a:hover {
  color: #167495;
  text-decoration: underline;
  background-color: unset;
}

.antd-pro-components-global-header-index-logo {
  display: inline-block;
  height: 64px;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: 64px;
  vertical-align: top;
  cursor: pointer;
}

.antd-pro-components-global-header-index-trigger {
  height: 64px;
  padding: 22px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}

.container-fluid {
  /* padding: 0 15px; */
  /* width: 100%; */
  margin: 24px;
  /* background-color: white; */
}

.ant-menu-title-content a {
  color: inherit;
}

.mt-10 {
  margin-top: 10px;
}

.card-filter .ant-card-head {
  background-color: rgb(240, 243, 245);
}

.card-filter.toggle .ant-card-head {
  border-radius: 8px;
}

.card-filter.toggle .ant-card-body {
  padding: 0px;
}

.frmFilter .ant-form-item {
  padding: 0 10px;
}

.frmFilter .col-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.frmFilter .col-button .ant-form-item {
  margin-bottom: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ant-input-number .ant-input-number-input {
  text-align: left;
}

.ant-picker {
  padding: 4px 0px 4px;
}

@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
  }
}

/* Min width 1600px */
@media screen and (max-width: 1600px) {}

/* Min width 1400px */
@media screen and (max-width: 1200px) {}

/* Max width 992px */
@media screen and (max-width: 991px) {}

/* Max width 767px */
@media screen and (max-width: 769px) {}

/* Max width 480px */
@media screen and (max-width: 481px) {
  .container-fluid {
    margin: 12px;
  }
}

/* Max width 480px */
@media screen and (max-width: 320px) {}